import Logo from "./web/assets/images/logo_white.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={Logo} alt="The Loupe Cafe" className="app-logo" />
        <p>
          While we're setting up our new feedback system, please bear with us
          and send a quick note to
        </p>
        <a href="mailto:support@loupe.cafe">support@loupe.cafe</a>
        <p className="highlight">It means a lot to us!</p>
      </header>
    </div>
  );
}

export default App;
